import React, { useState } from "react";
import { Link } from "react-router-dom";

const About = () => {
	const [activeTab, setActiveTab] = useState(1);
	const [activeFAQs, setActiveFAQs] = useState({ 0: true, 1: true, 2: true });
	const [testimonialIndex, setTestimonialIndex] = useState(0);

	// Subscriptions Data
	const subscriptionOptions = [
		{
			type: "Individual Subscription",
			price: "$6.99/month",
			description: "Ideal for individual users.",
			features: [
				"Unlimited Cover Letters and Resumes",
				"Full Access to Networking Pro",
				"Basic Headshot Generator",
			],
		},
		{
			type: "Enterprise Subscription",
			price: "$25.99/month",
			description: "Best for teams.",
			features: [
				"Same Features as Individual Subscription",
				"Up to 5 User Accounts",
				"Priority Support",
			],
		},
	];

	// Testimonials Data
	const testimonials = [
		{
			quote:
				"AI Application Assistant transformed how I approach job applications. Highly recommended!",
			author: "Jamie",
		},
		{
			quote: "The cover letters generated were spot on. Saved me hours!",
			author: "Taylor",
		},
		{
			quote:
				"Great for quick, professional applications. I loved the customization!",
			author: "Alex",
		},
		{
			quote: "This tool made my job hunt so much easier and more efficient!",
			author: "Jordan",
		},
		{
			quote: "I've never felt more confident in my applications. Thank you!",
			author: "Casey",
		},
		{
			quote:
				"The AI's understanding of industry-specific terminology is impressive.",
			author: "Morgan",
		},
		// Add more testimonials here
	];

	// FAQ Data
	const faqData = [
		{
			question: "How is each cover letter personalized to my profile?",
			answer:
				"Our AI analyzes your resume and aligns it with the job description to create a unique cover letter.",
		},
		{
			question: "What AI technology is used in the application?",
			answer:
				"We use advanced NLP algorithms to understand and match your skills with job requirements.",
		},
		{
			question: "Is my data secure?",
			answer:
				"Yes, we prioritize data security and confidentiality in our application.",
		},
		{
			question: "Can I customize the cover letter generated by the AI?",
			answer:
				"Yes, our AI provides a strong starting point, but you can customize and tweak the cover letter to your liking.",
		},
		{
			question: "How long does it take to generate a cover letter?",
			answer:
				"Our AI works quickly! It typically takes just a few seconds to generate a personalized cover letter.",
		},
		{
			question:
				"Do you offer support if I encounter issues with the application?",
			answer:
				"Absolutely! We have a dedicated support team ready to assist you with any issues or questions.",
		},
		{
			question: "Is AI Application Assistant suitable for all industries?",
			answer:
				"Yes, our AI is designed to cater to a wide range of industries by adapting to various job descriptions and resume styles.",
		},
		{
			question: "How does your AI ensure the cover letter is free of errors?",
			answer:
				"Our AI incorporates advanced error-checking algorithms to ensure your cover letter is grammatically correct and free of typos.",
		},
		{
			question:
				"Can I use AI Application Assistant if I have a non-traditional career path?",
			answer:
				"Definitely. Our AI is adept at handling diverse career backgrounds and can highlight your unique experiences effectively.",
		},
		{
			question:
				"Is there a limit to how many cover letters I can generate with a subscription?",
			answer:
				"With a subscription, you can generate an unlimited number of cover letters during the subscription period.",
		},
	];

	const nextTestimonial = () => {
		setTestimonialIndex((testimonialIndex + 1) % testimonials.length);
	};

	const previousTestimonial = () => {
		setTestimonialIndex(
			(testimonialIndex - 1 + testimonials.length) % testimonials.length
		);
	};

	const toggleFAQ = (index) => {
		setActiveFAQs((prevActiveFAQs) => {
			return { ...prevActiveFAQs, [index]: !prevActiveFAQs[index] };
		});
	};
	return (
		<div className='about-container'>
			<section className='hero-section'>
				<div className='hero-content'>
					<h1 className='hero-title'>What is AI Application Assistant?</h1>
					<p className='hero-subtitle'>
						Transform the way you apply for jobs with AI Application Assistant,
						your ultimate tool for creating standout application materials in
						minutes. Harnessing the power of advanced AI, our platform
						customizes resumes, cover letters, and job applications to perfectly
						match the job description, ensuring you bypass automated filters and
						impress hiring managers. But we don't stop there. AI Application
						Assistant also offers Networking Pro, a feature designed to help you
						connect with key industry professionals, and a Headshot Generator to
						create professional photos that make your application shine. Whether
						you're a job seeker, recruiter, or freelancer, AI Application
						Assistant streamlines your application process, saves you valuable
						time, and boosts your chances of landing your dream job. Get started
						today and take the first step towards a brighter career.
					</p>
				</div>
			</section>

			{/* Embedded Video or Demo Section */}
			<section className='video-demo-section'>
				<h1>See It In Action</h1>
				<p>
					Watch this short video to see how AI Application Assistant can help
					you land your dream job.
				</p>
				<br />
				<div className='video-wrapper'>
					<iframe
						width='560'
						height='315'
						src='https://www.youtube.com/embed/bEvKt9SNuW4?si=NIQEm-8VvzTgJLog'
						title='YouTube video player'
						frameborder='0'
						allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
						allowfullscreen></iframe>
				</div>
			</section>

			<br />

			{/* Interactive How It Works Section */}
			<div className='how-it-works'>
				<h1>How It Works</h1>
				<p>
					AI Application Assistant is designed to be intuitive and
					user-friendly. Click on each tab to learn more about how to use our
					platform to land your dream job.
				</p>
				<br />

				<div className='tabs'>
					<button
						onClick={() => setActiveTab(1)}
						className={activeTab === 1 ? "active" : ""}>
						Cover Letter Creation
					</button>
					<button
						onClick={() => setActiveTab(2)}
						className={activeTab === 2 ? "active" : ""}>
						Resume Creation
					</button>
					<button
						onClick={() => setActiveTab(3)}
						className={activeTab === 3 ? "active" : ""}>
						Networking Pro
					</button>
					<button
						onClick={() => setActiveTab(4)}
						className={activeTab === 4 ? "active" : ""}>
						Headshot Generator
					</button>
				</div>

				<br />

				<div className='tab-content'>
					{activeTab === 1 && (
						<h2>
							To create a cover letter, click the "Generate a New Cover Letter"
							button on the Dashboard page. Next, enter the job title, company
							name, location of the position, job description, and paste your
							resume. Then, click the "Generate Cover Letter" button to create a
							personalized cover letter tailored to the job description.
						</h2>
					)}
					{activeTab === 2 && (
						<h2>
							To create a resume using your existing resume, click the "Create a
							New Resuming Using Your Existing Resume" button on the Dashboard
							page, and follow the same steps as creating a cover letter. To
							create a resume from scratch, click the "Create a New Resume from
							Scratch" button on the Dashboard page, and fill in the required
							fields.
						</h2>
					)}
					{activeTab === 3 && (
						<h2>
							To use the Networking Pro feature, click the "Networking Pro"
							button on the Dashboard page. Next, enter the domain name of the
							target company into the search bar. Then, for a more focused
							search, add filters to search for contacts within specific
							departments. Finally, click the "Search" button to generate a list
							of potential contacts at the target company.
						</h2>
					)}
					{activeTab === 4 && (
						<h2>
							To use the Headshot Generator feature, click the "Headshot
							Generator" button on the Dashboard page. Next, upload at least 3x
							photos of yourself, and select the desired style. Then, click the
							"Generate Headshot" button to create a professional headshot that
							you can use for your job applications.
						</h2>
					)}
				</div>
			</div>

			<br />
			<br />

			{/* Subscription Options Section */}
			<div className='subscription-section'>
				<h2>Explore Our Subscription Plans</h2>
				<div className='subscription-cards'>
					{subscriptionOptions.map((option, index) => (
						<div key={index} className='subscription-card'>
							<h3>{option.type}</h3>
							<span className='price'>{option.price}</span>
							<p>{option.description}</p>
							<ul className='features-list'>
								{option.features.map((feature, i) => (
									<li key={i}>{feature}</li>
								))}
							</ul>
							<br />
							<Link to='/register' className='learn-more-btn'>
								Learn More
							</Link>
						</div>
					))}
				</div>
			</div>

			<br />
			<br />

			{/* Interactive Testimonials Section */}
			<div className='testimonials-section'>
				<h1>User Testimonials</h1>
				<div className='testimonial'>
					<blockquote>
						"{testimonials[testimonialIndex].quote}" -{" "}
						{testimonials[testimonialIndex].author}
					</blockquote>
					<div className='testimonial-buttons'>
						<button onClick={previousTestimonial}>&lt;</button>
						<button onClick={nextTestimonial}>&gt;</button>
					</div>
				</div>
			</div>

			<br />
			<br />

			<section className='press-mentions'>
				<h2>As Featured In</h2>
				<div className='press-logos'>
					<a
						href='https://finance.yahoo.com/news/ai-application-assistant-launches-innovative-133200039.html'
						target='_blank'
						rel='noopener noreferrer'>
						<img
							src='https://onedrive.live.com/embed?resid=4B1C8510CEF67D18%218195&authkey=%21ANCR0O1xkw2RoqI&width=1600&height=586'
							alt='Yahoo Finance Logo'
						/>
					</a>
					<a
						href='https://www.marketwatch.com/press-release/ai-application-assistant-launches-innovative-tool-to-empower-job-seekers-and-recruiters-b4ac16a2?mod=search_headline'
						target='_blank'
						rel='noopener noreferrer'>
						<img
							src='https://onedrive.live.com/embed?resid=4B1C8510CEF67D18%218197&authkey=%21AD67u8PPQB_cU1U&width=900&height=500'
							alt='MarketWatch Logo'
						/>
					</a>
				</div>
			</section>

			<br />
			<br />

			{/* FAQ Section with More Questions and Answers */}
			<div className='faq-section'>
				<h1>Frequently Asked Questions</h1>
				<div className='faq-list'>
					{faqData.map((faq, index) => (
						<div key={index} className='faq-item'>
							<div className='faq-question' onClick={() => toggleFAQ(index)}>
								<b>{faq.question}</b>
							</div>
							{activeFAQs[index] && (
								<div className='faq-answer'>{faq.answer}</div>
							)}
						</div>
					))}
				</div>
			</div>

			<br />
			<br />

			<div className='special-offer'>
				<h2>Ready to give it a try?</h2>
				<p>Create Two Cover Letters for Free by Creating an Account!</p>
				<Link to='/register' className='btn special-offer-btn'>
					Sign Up Now
				</Link>
			</div>

			<br />
			<br />
			<br />
		</div>
	);
};

export default About;
